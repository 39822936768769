import { createRouter, createWebHistory } from 'vue-router'
import Auth from '../views/template/Auth.vue'
import RecoverPassword from '../views/template/RecoverPassword.vue'
import Content from '../views/template/Content.vue'
import { getUser } from '@/config/global'

//Components
import DashBoard from '@/components/dashboard/Dashboard.vue'
import Bets from '@/components/bets/Bets.vue'
import Team from '@/components/teams/Teams.vue'
import Template from '@/components/Template.vue'

import NewGame from '@/components/admin/NewGame.vue'
import NewTeam from '@/components/admin/NewTeam.vue'
import NewUser from '@/components/admin/NewUser.vue'

import Balance from '@/components/bank/Balance.vue'
import Graphs from '@/components/bank/Graphs.vue'

const routes = [
  {
    path: '/',
    name: 'Content',
    component: Content,
    children:[
      {
        path: '/dashboard',
        component: DashBoard,
        meta: {
          requiresAuth: true,
          requiresAdmin: false
        }
      },
      {
        path: '/template',
        component: Template,
        meta: {
          requiresAuth: true,
          requiresAdmin: false
        }
      },
      {
        path: '/bets',
        component: Bets,
        meta: {
          requiresAuth: true,
          requiresAdmin: false
        }
      },
      {
        path: '/teams',
        component: Team,
        meta: {
          requiresAuth: true,
          requiresAdmin: false
        }
      },
      {
        path: '/banks/balance',
        component: Balance,
        meta: {
          requiresAuth: true,
          requiresAdmin: false
        }
      },
      {
        path: '/banks/graphs',
        component: Graphs,
        meta: {
          requiresAuth: true,
          requiresAdmin: false
        }
      },
      {
        path: '/admin/new-game',
        component: NewGame,
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: '/admin/new-team',
        component: NewTeam,
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: '/admin/new-user',
        component: NewUser,
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      }
    ],
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/definepw',
    name: 'RecoverPassword',
    component: RecoverPassword,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  }

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth) {
      let user = getUser()
      if (!user) {
        return {path:'/auth'}
      } 
  } 

})

export default router
