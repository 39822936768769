<template>
  <div class="dashboard">
    <h1>Dashboard</h1>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>  
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p> 
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
   <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam, quia. Nesciunt dolor incidunt expedita ducimus optio et,
      fuga tempore, nostrum cum assumenda unde deleniti, accusantium dolores ab! Magni, quaerat aliquam?
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
  .dashboard {
    border: 1px solid #fff;
  }
</style>