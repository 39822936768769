<template>
  <h2>Bets</h2>
</template>

<script>
export default {
    name: 'Bets'
}
</script>

<style>

</style>