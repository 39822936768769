<template>
    <div class="nav-dropdown">
        <a href="#" class="nav-link nav-link-dropdown" :title="name">
            <i class="nav-icon fa " :class="iconClass"></i>
            <span class="nav-name">{{ name }}</span>
            <i class="fa fa-chevron-right fa-2x nav-icon nav-dropdown-icon" aria-hidden="true"></i>
        </a>
        <div class="nav-dropdown-collapse">
            <div class="nav-dropdown-content" >
                <template v-for="item in subMenu" :key="item.name">
                    <a href="#" class="nav-dropdown-item nav-link" @click="router(item.path)">{{ item.name }}</a>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: {
        name:{
            type:String,
            required:true
        },
        iconClass:{
            type:String,
            required:true
        },
        subMenu:{
            type: Array,
            required:true
        }
    },
    methods: {
        router(path) {
            this.$router.push({ path: path })
        }
    },

}
</script>

<style>

</style>