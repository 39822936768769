<template>
  <div>
    <Menu />
      <router-view></router-view>
  </div>
</template>

<script>
import Menu from '@/components/menu/Menu'
export default {
  components: { Menu },
    name: 'content'
}
</script>

<style>
</style>