<template>
    <a href="#" class="nav-link" @click="router()" :title="name">
        <i class="nav-icon fa " :class="iconClass"></i>
        <span class="nav-name">{{ name }}</span>
    </a>
</template>

<script>
export default {
    name: 'NavLink',
    props: {
        name: {
            type: String,
            required: true
        },
        iconClass: {
            type:String,
            required:true
        },
        path: {
            type:String
        }

    },
    methods: {
        router() {
            this.$router.push({ path: this.path })
        }
    }
}
</script>

<style>

</style>