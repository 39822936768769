<template>
  <div class="container">
    <h1 class="h1-text">Form</h1>
    <p class="p-text">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum pariatur placeat a.</p>
    <form action="" class="form-container">
      <div class="row">
        <div class="column">
          <label class="form-label" for="name">Name</label>
          <input class="form-control" type="text" id="name" placeholder="your name here">
        </div>
        <div class="column">
          <label class="form-label" for="email">Email</label>
          <input class="form-control" type="text" id="email" placeholder="your email here">
        </div>
                <div class="column">
          <label class="form-label" for="date">Date</label>
          <input class="form-control" type="date" id="date" placeholder="your name here">
        </div>
        <div class="column">
          <label class="form-label" for="state">State</label>
          <select class="form-control" name="state" id="state">
            <option value=""> --- Choose --- </option>
            <option value="sp">Sao Paulo</option>
            <option value="rj">Rio de Janeiro</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <label class="form-label" for="subject">Subject</label>
          <input class="form-control" type="text" id="subject" placeholder="Your subject here">
        </div>
        <div class="column">
            <label class="form-label" for="contact">Contact Number</label>
            <input class="form-control" type="tel" id="contact" placeholder="Your phone number here">
        </div>
      </div>
      <div class="row">
        <div class="column">
          <label class="form-label" for="issue">Describe your issue</label>
          <textarea class="form-control" id="issue" placeholder="Describe your issue in detail here" rows="3"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <label class="form-label" for="issue">Languages</label>
          <div class="form-group">
            <div class="radio-button">
                <input type="radio" name="lang" id="pt-br" />
                <label for="pt-br" class="radio-item">Portuguese</label>
            </div>
            <div class="radio-button">
                <input type="radio" name="lang" id="span" />
                <label for="span" class="radio-item">Span</label>
            </div>
            <div class="radio-button">
                <input type="radio" name="lang" id="english" />
                <label for="english" class="radio-item">English</label>
            </div>
          </div>
        </div>
        <div class="column">
          <label class="form-label" for="issue">League</label>
          <div class="form-group">
            <div class="radio-button">
                <input type="checkbox" name="liga" id="brasil-serire-a" />
                <label for="brasil-serire-a" class="radio-item">Brasil serie A</label>
            </div>
            <div class="radio-button">
                <input type="checkbox" name="liga" id="la-liga" />
                <label for="la-liga" class="radio-item">La liga</label>
            </div>
            <div class="radio-button">
                <input type="checkbox" name="liga" id="premier" />
                <label for="premier" class="radio-item">Premier League</label>
            </div>
          </div>
        </div>
        <div class="column">
            <label class="form-label" for="contact">Image</label>
            <div>
              <label for="arquivo" class="label-file"><i class="fa fa-cloud-upload fa-1x" aria-hidden="true"></i> upload</label>
              <input type="file" class="form-file" name="arquivo" id="arquivo">
            </div>
        </div>
      </div>
    </form>
    <hr>
    <h1 class="h1-text">Cards</h1>
    <p class="p-text">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum pariatur placeat a.</p>
    <div class="card-container">
        <div class="card-wrapper">
            <div class="card">
                <div class="image-content">
                    <span class="overlay"></span>
                    <div class="card-image">
                        <img src="@/assets/img/profile1.jpg" alt="" class="card-img">
                    </div>
                </div>
                <div class="card-content">
                    <h2 class="name">David Dell</h2>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                    </p>
                    <button class="button">View More...</button>
                </div>
            </div>
        </div>
        <div class="card-wrapper">
            <div class="card">
                <div class="image-content">
                    <span class="overlay"></span>
                    <div class="card-image">
                        <img src="@/assets/img/profile2.jpg" alt="" class="card-img">
                    </div>
                </div>
                <div class="card-content">
                    <h2 class="name">David Dell</h2>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                    </p>
                    <button class="button">View More...</button>
                </div>
            </div>
        </div>
        <div class="card-wrapper">
            <div class="card">
                <div class="image-content">
                    <span class="overlay"></span>
                    <div class="card-image">
                        <img src="@/assets/img/profile3.jpg" alt="" class="card-img">
                    </div>
                </div>
                <div class="card-content">
                    <h2 class="name">David Dell</h2>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                    </p>
                    <button class="button">View More...</button>
                </div>
            </div>
        </div>
        <div class="card-wrapper">
            <div class="card">
                <div class="image-content">
                    <span class="overlay"></span>
                    <div class="card-image">
                        <img src="@/assets/img/profile4.jpg" alt="" class="card-img">
                    </div>
                </div>
                <div class="card-content">
                    <h2 class="name">David Dell</h2>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                    </p>
                    <button class="button">View More...</button>
                </div>
            </div>
        </div>
        <div class="card-wrapper">
            <div class="card">
                <div class="image-content">
                    <span class="overlay"></span>
                    <div class="card-image">
                        <img src="@/assets/img/profile5.jpg" alt="" class="card-img">
                    </div>
                </div>
                <div class="card-content">
                    <h2 class="name">David Dell</h2>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                    </p>
                    <button class="button">View More...</button>
                </div>
            </div>
        </div>
        <div class="card-wrapper">
            <div class="card">
                <div class="image-content">
                    <span class="overlay"></span>
                    <div class="card-image">
                        <img src="@/assets/img/profile6.jpg" alt="" class="card-img">
                    </div>
                </div>
                <div class="card-content">
                    <h2 class="name">David Dell</h2>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                    </p>
                    <button class="button">View More...</button>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <h1 class="h1-text">Table</h1>
    <p class="p-text">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum pariatur placeat a.</p>
    <div class="table-content">
        <table class="table-responsive">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Marks%</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td data-label="S.No">1</td>
                    <td data-label="Name">Dinesh</td>
                    <td data-label="Age">34</td>
                    <td data-label="Marks%">50%</td>
                    <td data-label="Staus">Passed</td>
                </tr>

                <tr>
                    <td data-label="S.No">2</td>
                    <td data-label="Name">Kamal</td>
                    <td data-label="Age">23</td>
                    <td data-label="Marks%">70%</td>
                    <td data-label="Staus">Passed</td>
                </tr>

                <tr>
                    <td data-label="S.No">3</td>
                    <td data-label="Name">Neha</td>
                    <td data-label="Age">20</td>
                    <td data-label="Marks%">90%</td>
                    <td data-label="Staus">Passed</td>
                </tr>

                <tr>
                    <td data-label="S.No">4</td>
                    <td data-label="Name">Priya</td>
                    <td data-label="Age">30</td>
                    <td data-label="Marks%">30%</td>
                    <td data-label="Staus">Failed</td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>