import { NotifyJS } from './../assets/js/NotifyJS.js'

export const baseURL = process.env.VUE_APP_ENV == 'development' ? 'http://127.0.0.1:8000' : 'https://betam-api.wandersontx.com';
export const userKey = '__betam_user__'

export const saveUser = function(data) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + 2);
    var value = JSON.stringify(data) + "; expires=" + exdate.toUTCString();
    document.cookie = userKey + "=" + value;
}

export const getUser = function() {
    let cookies = document.cookie.split(';')
    if (cookies.length === 0)
        return null;

    let user = cookies.filter(item => {
        if (item.split('=')[0].trim() === userKey)
            return item
    })

    return user.length === 0 ? null : JSON.parse(user[0].split('=')[1]);
}

export const deleteUser = function(){
    document.cookie = userKey+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export const notify = new NotifyJS();

export const showError = function(error) {
    const notify = new NotifyJS();
    if (error.status === 400) {
        console.log(error)
        let errors = error.data.errors
        let strError = ''
        for (let item of Object.keys(errors)) {
            strError += errors[item][0] + '<br>';
        }
        notify.error({title: error.data.message, description: strError})
    } else {
        notify.error(error.data.message)
    }
}
