<template>

  <div>
    <header class="header" id="header-pd">
        <div class="header-container">
            <img src="@/assets/img/user.png" alt="" class="header-img">
             <i class="bx bx-menu header-logo" @click="collapseMenu()"></i>
            <!-- <div class="header-search">
                <input type="search" name="" placeholder="Search" class="header-input">
                <i class="fa fa-search header-icon"></i>
            </div> -->
            <div class="header-toggle">
                <i class="bx bx-menu" id="header-toggle"></i>
            </div>
        </div>
    </header>
    <div class="nav" id="navbar">
        <nav class="nav-container">
            <div>
                <a href="#" class="nav-link nav-logo">
                    <img class="nav-icon nav-icon-logo" src="@/assets/img/logo-128-x.png" alt="">
                    <span class="nav-logo-name">Betam</span>
                </a>
                <div class="nav-list">
                    <div class="nav-items">
                        <NavLink name="Template" iconClass="fa-pencil" path="/template" />
                        <NavLink name="Dashboard" iconClass="fa-bar-chart" path="/dashboard" />
                        <NavLink name="Bets" iconClass="fa-ticket" path="/bets"/>
                        <NavLink name="Teams" iconClass="fa-futbol-o" path="/teams" />
                        <Dropdown name="Bank" iconClass="fa-university" 
                            :subMenu="[
                            {name:'Balance', path: '/banks/balance'},
                            {name:'Graphs', path: '/banks/graphs'},
                            ]"
                        />
                        <Dropdown name="Admin" iconClass="fa-shield" 
                            :subMenu="[
                            {name:'New User', path: '/admin/new-user'},
                            {name:'New Team', path: '/admin/new-team'},
                            {name:'New Game', path: '/admin/new-game'},
                            ]"
                        />
                    </div>
                </div>
            </div>
            <a href="#" class="nav-link nav-logout" id="nav-logout" @click="logout()">
                <i class="fa fa-sign-out nav-icon"></i>
                <nav class="nav-name">Logout</nav>
            </a>
        </nav>
    </div>
  </div>
</template>
<script>
import NavLink from './NavLink.vue'
import Dropdown from './Dropdown.vue'
import { deleteUser } from '../../config/global.js'
export default {
    components: { NavLink, Dropdown },
    name: 'Menu',
    methods: {
        menuFunctions() {
            /* ============== SHOW NAVBAR ================== */
                const showMenu = (headerToggle, navbarId) => {
                    const toggleBtn = document.getElementById(headerToggle)
                    let nav = document.getElementById(navbarId)

                    if (headerToggle && navbarId) {
                        toggleBtn.addEventListener('click', ()=>{
                            nav.classList.toggle('show-menu')
                            toggleBtn.classList.toggle('bx-x')
                        })
                    }
                }
                showMenu('header-toggle', 'navbar')

            /* ============== LINK ACTIVE ================== */
                const linkColor = document.querySelectorAll('.nav-link')

                function colorLink() {
                    let isDropDown = this.className.match(/nav-link-dropdown/)
                    let isNavDropDownItem = this.className.match(/nav-dropdown-item/)
                    let isNavLogo = this.className.match(/nav-logo/)
                    
                    if (!isDropDown && !isNavLogo) {
                        linkColor.forEach(l => l.classList.remove('active'))
                        this.classList.add('active')
                    }

                    if (isNavDropDownItem) {
                        let navDropdown = this.parentElement.parentElement.parentElement.firstChild
                        navDropdown.classList.add('active')
                    }
                }

                linkColor.forEach(l => l.addEventListener('click', colorLink))
 

            /* ============== SHOW DROPDOWN ================== */
                document.querySelectorAll('.nav-link-dropdown').forEach(item => item.addEventListener('click', function(){
                    this.parentElement.classList.toggle("nav-dropdown-show")
                    this.firstChild.nextSibling.nextSibling.classList.toggle("nav-dropdown-icon-rotate")
                    let isDropdownOpen = this.firstChild.nextSibling.nextSibling.className.match(/nav-dropdown-icon-rotate/)
                    let isSideBarShow = document.getElementById('body-base').className.match(/body-pd/)

                    if (window.screen.availWidth >= 768 && isDropdownOpen && !isSideBarShow) {
                        document.getElementById('body-base').classList.toggle('body-pd')
                        document.getElementById('header-pd').classList.toggle('body-pd')
                        document.getElementById('navbar').classList.toggle('nav-pd')
                        let isShowMenu = document.getElementById('body-base').className.match(/body-pd/)
                        if (!isShowMenu) {
                            document.querySelectorAll('.nav-dropdown').forEach(l => l.classList.remove('nav-dropdown-show'))
                            document.querySelectorAll('.nav-dropdown-icon').forEach(l => l.classList.remove('nav-dropdown-icon-rotate'))
                            document.querySelectorAll('.nav-name').forEach(l => l.classList.add('hide'))
                            document.querySelectorAll('.nav-dropdown-icon').forEach(l => l.classList.add('hide'))
                        } else {
                            document.querySelectorAll('.nav-name').forEach(l => l.classList.remove('hide'))
                            document.querySelectorAll('.nav-dropdown-icon').forEach(l => l.classList.remove('hide'))
                        }
                    }
                }))
        },
        collapseMenu() {
            document.getElementById('body-base').classList.toggle('body-pd')
            document.getElementById('header-pd').classList.toggle('body-pd')
            document.getElementById('navbar').classList.toggle('nav-pd')
            let isShowMenu = document.getElementById('body-base').className.match(/body-pd/)
            if (!isShowMenu) {
                document.querySelectorAll('.nav-dropdown').forEach(l => l.classList.remove('nav-dropdown-show'))
                document.querySelectorAll('.nav-dropdown-icon').forEach(l => l.classList.remove('nav-dropdown-icon-rotate'))
                document.querySelectorAll('.nav-name').forEach(l => l.classList.add('hide'))
                document.querySelectorAll('.nav-dropdown-icon').forEach(l => l.classList.add('hide'))
            }else {
                document.querySelectorAll('.nav-name').forEach(l => l.classList.remove('hide'))
                document.querySelectorAll('.nav-dropdown-icon').forEach(l => l.classList.remove('hide'))
            }
        },
        logout() {
            let yesno = confirm('Are you sure to exit this page?')
            if (yesno) {
                deleteUser()
                this.$router.push({name: 'Auth'})
            }
        }
 
    },
    mounted() {
        this.menuFunctions()
        if(window.screen.availWidth >= 768) {
            this.collapseMenu()
        }
    },
    updated() {
        this.menuFunctions()
    },
}
</script>

<style>
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--header-color);
    box-shadow: 0 1px 0 rgba(22, 8, 43, .1);
    padding: 0 1rem;
    z-index: var(--z-fixed);
  }

  .header-container {
    display: flex;
    align-items: center;
    height: var(--header-height);
    justify-content: space-between;
  }

  .header-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .header-logo {
    color: var(--title-color);
    font-weight: var(--font-medium);
    display: none;
    cursor: pointer;
  }

  .header-search {
    display: flex;
    padding: .40rem .75rem;
    background-color: var(--container-color);
    border-radius: .25rem;
  }

  .hide {
    display: none;
  }


  .header-input {
    width: 100%;
    border: none;
    outline: none;
    background-color: var(--container-color);
    color: #fff;
    font-size: var(--normal-font-size);
  }

  .header-input::placeholder {
    font-family: var(--body-font);
    color: #fff;
  }

  .header-icon {
    color: #fff;
  }

  .nav-icon-logo {
    max-width: 30px;
  }

  .header-icon,
  .header-toggle {
    font-size: 1.2rem;
  }

  .header-toggle {
    color: var(--title-color);
    cursor: pointer;
  }

  .body-pd {
    padding-left: 0;
  }

    /* =========== Side Bar  ========== */
  .nav {
        position: fixed;
        top: 0;
        left: -100%;
        height: 100vh;
        padding: 1rem 1rem 3rem;
        background-color: var(--container-color);
        box-shadow: 1px 0 0 rgba(22, 8, 43, .1);
        z-index: var(--z-fixed);
        transition: .2s;
    }

    .nav-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;
        scrollbar-width: none; /* For mozila */
        height: -webkit-fill-available;
    }

    .nav-container::-webkit-scrollbar {
        display: none; /* For Google Chrome and others */
    }

    .nav-logo {
        font-weight: var(--font-semi-bold);
        margin-bottom: 2.5rem;
    }

    .nav-list,
    .nav-items {
        display: grid;
    }

    .nav-list {
        row-gap: 2.5rem;
    }

    .nav-items {
        row-gap: 1.5rem;
    }


    .nav-link {
        display: flex;
        align-items: center;
        color: var(--text-color);
    }

    .nav-link:hover:not(.nav-logo) {
        color: var(--active-color);
    }

    .nav-icon {
        font-size: .8rem;
        margin-right: .5rem;
    }

    .nav-name {
        font-size: var(--small-font-size);
        font-weight: var(--font-medium);
        white-space: nowrap;
    }

    .nav-logout {
        margin-top: 5rem;
        margin-bottom: 1.5rem;
    }

    /* ===== Dropdown ===== */
    .nav-dropdown {
        overflow: hidden;
        max-height: 21px;
        transition: .4s ease-in-out;
    }

    .nav-dropdown-collapse {
        background-color: var(--container-color-light);
        border-radius: .25rem;
        margin-top: 1rem;
    }

    .nav-dropdown-content {
        display: grid;
        row-gap: .5rem;
        padding: .75rem 2.5rem .75rem .75rem;
    }

    .nav-dropdown-item {
        font-size: var(--small-font-size);
        font-weight: var(--font-medium);
        color: var(--text-color);
    }

    .nav-dropdown-item:hover{
        color: var(--active-color);
    }

    .nav-dropdown-icon {
        margin-left: auto;
        transition: .4s;
    }

    /* ===== show collapse ===== */
    /* .nav-dropdown:hover{
        max-height: 100rem; 
    } */

    .nav-dropdown-show{
        max-height: 100rem; 
        transition: .2s;
    }

    .nav-dropdown-icon-rotate {
        transform: rotate(90deg);
    }


    /* ========== show menu =========== */
    .show-menu {
        left: 0;
    }

    /* ========== Active link =========== */
    .active {
        color: var(--active-color);
    }

    /* ========== MEDIA QUERIES =========== */
    /* For small devices reduce search */
    @media screen and (max-width: 320px) {
        .header-search {
            width: 70%;
        }
    }

    @media screen and (min-width: 768px) {
        body {
            padding: 1rem 3rem 0 6rem;
        }

        .header {
            padding: 0 3rem 0 6rem;
        }

        .header-container {
            height: calc(var(--header-height) + .5rem);
        }

        .header-search {
            width: 700px;
            padding: .55rem .75rem;
        }

        .header-toggle {
            display: none;
        }

        .header-logo {
            display: block;
            font-size: 1.6rem;
        }

        .header-img {
            width: 40px;
            height: 40px;
            order: 1;
        }

        .nav-logout {
            margin-bottom: 0;
        }

        .nav-items {
            row-gap: 1.7rem;
        }

        .nav-icon {
            font-size: .9rem;
        }

        .nav {
            left: 0;
            padding: 1.2rem 1.5rem 3rem;
            width: 75px;
        }

        .nav-logo-name,
        .nav-name,
        .nav-dropdown-icon {
            opacity: 0;
            transition: .3s;
        } 

         .nav-pd {
            width: var(--nav-width);
        } 

        .body-pd {
            padding-left: calc(var(--nav-width) + 1rem);
        }

        .nav-logo-name {
            opacity: 1;
        }

        .nav-name {
            opacity: 1;
        }

        .nav-dropdown-icon {
            opacity: 1;
        }
    }
</style>