<template>
    <div class="authentication">
        <div class="auth">
            <form action="">
                <img src="@/assets/img/logo-128-x.png" alt="">
                <h2>Betam</h2>

                <!-- signin -->
                <template v-if="mode === 'signin'">
                    <div class="div-input">
                        <div class="i">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </div>
                        <div class="div">
                            <h5>Email</h5>
                            <input type="text" class="input" v-model="user.email">
                        </div>
                    </div>
                    <div class="div-input">
                        <div class="i">
                            <i class="fa fa-lock" aria-hidden="true"></i>
                        </div>
                        <div class="div">
                            <h5>Password</h5>
                            <input type="password" class="input" v-model="user.password">
                        </div>
                    </div>
                    <input type="button" value="sign in" class="btn" @click.prevent="signin">
                    <div class="options">
                        <a href="#" @click="setMode('signup')">Signup</a>
                        <a href="#" @click="setMode('sendEmailRecoverPassword')">I forgot my password</a>
                    </div>
                </template>
                <!-- /signin -->

                <!-- signup -->
                <template v-else-if="mode === 'signup'">
                    <div class="div-input">
                        <div class="i">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <div class="div">
                            <h5>Name</h5>
                            <input type="text" class="input" v-model="user.name">
                        </div>
                    </div>    
                    <div class="div-input">
                        <div class="i">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </div>
                        <div class="div">
                            <h5>Email</h5>
                            <input type="text" class="input" v-model="user.email">
                        </div>
                    </div>
                    <div class="div-input">
                        <div class="i">
                            <i class="fa fa-lock" aria-hidden="true"></i>
                        </div>
                        <div class="div">
                            <h5>Password</h5>
                            <input type="password" class="input" v-model="user.password">
                        </div>
                    </div>
                    <div class="div-input">
                        <div class="i">
                            <i class="fa fa-lock" aria-hidden="true"></i>
                        </div>
                        <div class="div">
                            <h5>Repeate Password</h5>
                            <input type="password" class="input" v-model="user.password_confirmation">
                        </div>
                    </div>
                    <input type="button" value="sign up" class="btn" @click.prevent="signup">
                    <a href="#" class="back-signin" @click="setMode('signin')">Signin</a>
                </template>
                <!-- /signup -->

                <!-- sendm email recover password -->
                <template v-else-if="mode === 'sendEmailRecoverPassword'">
                    <p class="txt-email">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui provident vitae cumque labore minus
                        minima eveniet, alias, corporis natus eius veniam esse totam eligendi magni facilis vero quod.
                        Iure, illum!
                    </p>
                    <div class="div-input">
                        <div class="i">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </div>
                        <div class="div">
                            <h5>Email</h5>
                            <input type="text" class="input" v-model="user.email">
                        </div>
                    </div>
                    <input type="button" value="send email" class="btn" @click.prevent="sendEmailRecoverPassword">
                    <a href="#" class="back-signin" @click="setMode('signin')">Signin</a>
                </template>
                <!-- sendm email recover password -->

            </form>
        </div>
    </div>
</template>

<script>
import { baseURL, saveUser, showError, notify } from '@/config/global'
import axios from 'axios'
export default {
    name: 'Auth',
    data: function(){
        return {
        mode: 'signin',
        user:{},
        }
    },
    methods: {
    setMode (mode = 'signin') {
        this.mode = mode;
        this.user = {}
    },
    signin() {
        axios.post(baseURL+'/signin', this.user)
        .then(resp => resp.data)
        .then(data => {
            saveUser(data)
            this.$router.push({name: 'Content'})
        })
        .catch(error => showError(error.response));
    },
    signup() {
        axios.post(baseURL+'/signup', this.user)
        .then(resp => {
            this.setMode('signin')
            this.user = {}
            notify.success('Account created successfully')

        })
        .catch(error => showError(error.response));
    },
    sendEmailRecoverPassword() {
        this.user['env'] = process.env.VUE_APP_ENV || 'development'
        axios.post(baseURL+'/sendEmailRecoverPassword', this.user)
        .then(resp => {
            this.setMode('signin')
            this.user = {}
            notify.success('Email sent successfully.')
        })
        .catch(error => showError(error.response));
    },
    loadJsFunctions() {
        const inputs = document.querySelectorAll(".input");
        function addcl(){
            let parent = this.parentNode.parentNode;
            parent.classList.add("focus");
        }

        function remcl(){
            let parent = this.parentNode.parentNode;
            if(this.value == ""){
                parent.classList.remove("focus");
            }
        }

        inputs.forEach(input => {
            input.addEventListener("focus", addcl);
            input.addEventListener("blur", remcl);
        });
    }
    },
    mounted() {
      this.loadJsFunctions()
    },
    updated() {
        this.loadJsFunctions()
    },
}
</script>

<style scope>
.authentication {
    background: linear-gradient( to bottom, #1b3933, #445464);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh; 
    display: flex;
    justify-content: center;
    overflow: auto;
}

.auth {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

form {
    width: 300px;
}

.auth img {
    height: 100px;
}

.auth h2 {
    margin: 0 0 15px 0;
    font-size: 2.9rem;
    font-style: italic;
}

.auth .div-input {
    position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 1px solid #d9d9d9;
}

.auth .div-input.one {
    margin-top: 0;
}

.i {
    display: flex;
    justify-content: center;
    align-items: center;
}

.i i {
    transition: .3s;
}

.div-input div {
    position: relative;
    height: 45px;
}

.div-input > div > h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(223, 221, 221);
    font-size: 18px;
    transition: .3s;
}

.div-input:after, .div-input:before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0%;
    height: 2px;
    background-color: #38d39f;
    transition: .4s;
}

.div-input:before {
right: 50%;
}

.div-input:after {
    left: 50%;
}

.div-input.focus:before, .div-input.focus:after {
    width: 50%;
}

.div-input.focus > div > h5 {
    top: -5px;
    font-size: 15px;
}

.div-input.focus > .i > i {
    color: #38d39f;
}

.div-input > div > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    color: #fff;
    font-family: 'poppins', sans-serif;
}

.btn {
    display: block;
    width: 100%;
    height: 50%;
    border-radius: 5px;
    outline: none;
    border: none;
    background: #4Cbb8B;
    background-size: 200%;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    margin: 1rem 0 .4rem 0;
    cursor: pointer;
    border: .5px solid #eee;
    box-shadow: 1px .5px 1px #cdcdcd;
    padding: 7px 0;
}

.btn:hover {
    background: #448C7C;
    color: #fff;
    border: .5px solid #eee;
}

.options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
}

.options > a {
    color: #fff;
    text-decoration: none;
    font-size: .8rem;
}

.options > a:hover {
    color: var(--active-color);
}

.back-signin {
    display: block;
    color: #fff;
    text-align: left;
    text-decoration: none;
    font-size: .8rem;
}

.txt-email {
    text-align: justify;
    font-size: .85rem;
}

@media screen and (min-width: 1000px){
    form {
    width: 450px;
}
}
</style>